'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.assets.factory:AssetTypesManager

 # @description

###
angular
  .module 'mundoAdmin.assets'
  .factory 'AssetTypesManager', [
    'Restangular'
    'MundoDefaultManager'
    '$q'
    (
      Restangular
      MundoDefaultManager
      $q
    ) ->
      AssetTypesManagerBase = new MundoDefaultManager()

      AssetTypesManagerBase.setUrl('assets/types')
      AssetTypesManagerBase.setActions(['list', 'print', 'search'])
      AssetTypesManagerBase.setSearchFields(['label'])

      AssetTypesManagerBase.getListCustom = ->
        types = []
        Restangular.all(@conf.url).customGET("").then((data) ->
          for k, v of data.results
            types.push(v)
          types
        )
        types

      AssetTypesManagerBase.getList = ->
        response = $q.defer()
        response.resolve @getListCustom()
        response.promise

      AssetTypesManagerBase.getListSchema = ->
        [
          key: 'label'
          title: 'datatable.label.label'
  #        sort: 'label'
         ,
          key: 'code'
          title: 'datatable.label.code'
  #        sort: 'code'
        ,
          key: 'dataTypes'
          title: 'datatable.label.data.types'
          type: 'array'
          hideInList: true
        ]

      AssetTypesManagerBase
  ]
